<template>
  <PageComponent title="Requests" @overlayClicked="closeDialog" :showOverlay="showOverlay">
    <template #dialogs>
      <AssignmentDialog @assignSelf="displayAvatar" @closeDialog="closeDialog" v-if="showAssignmentDialog"
                        class="z-40 absolute-center" @assignToPartner="triggerShowConfirmationDialog"/>
      <Dialog title=" Are you sure you want to assign this trip request?" :text="confirmationSummary"
              v-show="showAssignmentConfirmationDialog"
              class="dialog" @approveDialog="approved" @cancelDialog="closeDialog"/>
    </template>
    <div >
      <div class="w-full relative overflow-hidden mb-8">
        <Tab :tabItems="tabSections" class="tab-mb"/>
      </div>
      <template v-if="isLoadingData">
        <Loading/>
      </template>
      <template v-else>
        <RequestTable
            :header="tableHeader"
            :requests="openRequests.data"
            :pagination="paginationData(openRequests)"
            @paginateTo="getRequests('open',$event)"
            v-if="showOpenRequests"
        />
        <RequestTable
            :header="tableHeader"
            :requests="draftRequests.data"
            :pagination="paginationData(draftRequests)"
            @paginateTo="getRequests('draft',$event)"
            v-if="showDraftRequests"
        />
        <RequestTable
            :header="tableHeader"
            :requests="changeRequests.data"
            :pagination="paginationData(changeRequests)"
            @paginateTo="getRequests('change',$event)"
            v-if="showChangeRequests"
        />
        <RequestTable
            :header="tableHeader"
            :requests="reviewRequests.data"
            :pagination="paginationData(reviewRequests)"
            @paginateTo="getRequests('review',$event)"
            v-if="showReviewRequests"
        />
        <RequestTable
            :header="tableHeader"
            :requests="pendingRequests.data"
            :pagination="paginationData(pendingRequests)"
            @paginateTo="getRequests('pending',$event)"
            v-if="showPendingRequests"
        />
        <RequestTable
            :header="tableHeader"
            :requests="completedRequests.data"
            :pagination="paginationData(completedRequests)"
            @paginateTo="getRequests('completed',$event)"
            v-if="showCompletedRequests"
        />
        <RequestTable
            :header="tableHeader"
            :requests="cancelledRequests.data"
            :pagination="paginationData(cancelledRequests)"
            @paginateTo="getRequests('cancelled',$event)"
            v-if="showCancelledRequests"
        />
      </template>
    </div>
  </PageComponent>
</template>

<script>
import AssignmentDialog from "@/components/Dialogs/AssignmentDialog";
import Loading from "@/components/Preloaders/Loading";
import RequestTable from "@/components/Requests/RequestTable";
import {mapActions, mapGetters} from "vuex";
import Dialog from "../../components/Dialogs/Dialog";
import {PageComponent, Tab} from "@/components";
export default {
  name: "Requests",
  components: {
    Dialog,
    AssignmentDialog,
    Loading,
    Tab,
    RequestTable,
    PageComponent
  },
  data() {
    return {
      tableHeader: [
        "Destination",
        "Reference Nr.",
        "Name",
        "Request value",
        "Date",
        "Status",
      ],
      tabSections: [
        {name: "Open", isActive: true, textColor: "text-black-base"},
        {name: "Drafts", isActive: false, textColor: "text-grey-dark"},
        {name: "Changes", isActive: false, textColor: "text-grey-dark"},
        {name: "Review", isActive: false, textColor: "text-grey-dark"},
        {name: "Pending", isActive: false, textColor: "text-grey-dark"},
        {name: "Completed", isActive: false, textColor: "text-grey-dark"},
        {name: "Cancelled", isActive: false, textColor: "text-grey-dark"},
      ],
      selectedOption: 'all',
      showDialog: true,
      confirmationSummary: "",
      currentSelectionDetails: {}
    };
  },

  beforeMount() {
    this.getTripRequests();
  },

  methods: {
    ...mapActions({
      hideAvatar: "requests/hideAvatar",
      displayAvatar: "requests/displayAvatar",
      getTripRequests: "requests/getTripRequests",
      closeOverlay: "requests/closeOverlay",
      hideAssignmentDialog: "requests/hideAssignmentDialog",
      assignTripRequestToPartner: 'requests/assignTripRequestToPartner',
      setShowAssignmentConfirmationDialog: 'requests/setShowAssignmentConfirmationDialog',
      getSpecificTripRequests: 'requests/getSpecificTripRequests'
    }),
    triggerShowConfirmationDialog(data) {
      this.currentSelectionDetails = data;
      this.confirmationSummary = data.summary;
      this.setShowAssignmentConfirmationDialog(true);
    },
    getRequests(tripRequestType, pageNumber) {
      this.getSpecificTripRequests({tripRequestType, pageNumber});
    },

    approved() {
      let requestData = {
        tripRequestId: this.currentSelectionDetails.tripRequestId,
        partnerId: this.currentSelectionDetails.partnerId,
        taBudget: this.currentSelectionDetails.taBudget
      }
      this.assignTripRequestToPartner(requestData).then(({data}) => {
        this.$notify({
          type: "success",
          title: "Success",
          text: data.message,
        });
        this.closeDialog()
      }).catch((error) => {
        this.$notify({
          type: "error",
          title: `${error.message}`,
          text: `${error.response.data.message}`,
        });
      })
    },
    closeDialog() {
      this.hideAssignmentDialog();
      this.setShowAssignmentConfirmationDialog(false)
      this.closeOverlay();
    },
    paginationData(data) {
      return {
        currentPage: data.currentPage,
        perPage: data.perPage,
        from: data.from,
        to: data.to,
        total: data.total,
        lastPage: data.lastPage
      }
    }
  },
  computed: {
    ...mapGetters({
      openRequests: "requests/openRequests",
      draftRequests: "requests/draftRequests",
      changeRequests: "requests/changeRequests",
      pendingRequests: "requests/pendingRequests",
      completedRequests: "requests/completedRequests",
      cancelledRequests: "requests/cancelledRequests",
      reviewRequests: "requests/reviewRequests",
      isLoadingData: "requests/isLoadingData",
      showOverlay: "requests/showOverlay",
      showAssignmentDialog: "requests/showAssignmentDialog",
      showAssignmentConfirmationDialog: "requests/showAssignmentConfirmationDialog",
    }),

    showOpenRequests() {
      return this.tabSections[0].isActive;
    },
    showDraftRequests() {
      return this.tabSections[1].isActive;
    },
    showChangeRequests() {
      return this.tabSections[2].isActive;
    },
    showReviewRequests() {
      return this.tabSections[3].isActive;
    },
    showPendingRequests() {
      return this.tabSections[4].isActive;
    },
    showCompletedRequests() {
      return this.tabSections[5].isActive;
    },
    showCancelledRequests() {
      return this.tabSections[6].isActive;
    },
  },
};
</script>
<style scoped>
.tab-mb {
  @apply sm:mb-0 overflow-x-auto w-full;
}
.dialog {
  @apply fixed z-40 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2;
}
</style>