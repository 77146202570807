<template>
  <div class="tooltip">
    <div>
      <svg
          :class="[placement]"
          v-show="top"
          x="0px"
          y="0px"
          viewBox="0 0 255 255"
          xml:space="preserve"
      >
        <polygon class="fill-current" points="0,0 127.5,127.5 255,0"/>
      </svg>
      <span>{{ text }}</span>
      <svg
          :class="[placement]"
          v-show="bottom"
          x="0px"
          y="0px"
          viewBox="0 0 255 255"
          xml:space="preserve"
      >
        <polygon class="fill-current" points="0,0 127.5,127.5 255,0"/>
      </svg>
    </div>
  </div>
</template>
<script>
export default {
  name: "Tooltip",
  props: {
    position: {
      type: String,
      default: "bottom right",
    },
    text: {
      type: String,
      default: "This is a tooltip👆🏾",
    },
  },
  computed: {
    bottom() {
      if (this.position.includes("bottom")) return true;
      return false;
    },
    placement() {
      let pos = this.position;
      if (pos.includes("left") && pos.includes("top")) return "left-top";
      if (pos.includes("right") && pos.includes("top")) return "right-top";
      if (pos.includes("center") && pos.includes("top")) return "center-top";
      if (pos.includes("center") && pos.includes("bottom"))
        return "center-bottom";
      if (pos.includes("left") && pos.includes("bottom")) return "left-bottom";
      return "right-bottom";
    },
    top() {
      if (this.position.includes("top")) return true;
      return false;
    },
  },
};
</script>
<style scoped>

.tooltip > div {
  @apply bg-black-base text-white text-xs rounded py-2 px-2 right-0 bottom-full;
  @apply shadow-xl;
}

.tooltip > div > span {
  @apply block;
}

.tooltip svg {
  @apply absolute text-black-base h-2;
}

.left-bottom {
  @apply left-3 top-full;
}

.center-bottom {
  @apply left-1/2 transform -translate-x-1/2 top-full;
}

.right-bottom {
  @apply right-3 top-full;
}

.center-top {
  transform: scaleY(-1) translateX(-50%);
  @apply left-1/2 bottom-full;
}

.left-top {
  transform: scaleY(-1);
  @apply left-3 bottom-full;
}

.right-top {
  transform: scaleY(-1);
  @apply right-3 bottom-full;
}
</style>